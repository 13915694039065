import { createTheme } from '@mui/material/styles';

// Function to create a theme with custom color schemes
export const getTheme = (mode) => createTheme({
  typography: {
    fontFamily: '"Playfair Display", serif, "Karla", sans-serif', // Custom font
  },
  palette: {
    mode, // 'light' or 'dark'
    ...(mode === 'light'
      ? {
          // palette values for light mode
          background: {
            default: '#FFF8F5', // Custom light background color
            paper: '#FFEDE7', // Custom light paper color
          },
          text: {
            primary: '#000', // Custom light text primary color
            secondary: '#fffff', // Custom light text secondary color
          },
          secondary: {
            main: '#aaaaaa', // Custom light border color
          }
        }
      : {
          // palette values for dark mode
          background: {
            default: '#121212', // Custom dark background color
            paper: '#1e1e1e', // Custom dark paper color
          },
          text: {
            primary: '#e8e8e8', // Custom dark text primary color
            secondary: '#a1a1a1', // Custom dark text secondary color
          },
        }),
  },
});
